import React, { useEffect, useState } from "react";
import {
  Table,
  Layout,
  Button,
  Spin,
  message,
  Modal,
  Input,
  Row,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsersRequest } from "../../store/slices/userSlice";
import { RootState } from "../../store/store";
import "./index.css";
import { api } from "../../store/sagas/authSaga";
const { Content } = Layout;
const { confirm } = Modal;

const AdminPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, loading, currentPage, pageSize, total, error } = useSelector(
    (state: RootState) => state.user
  );
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    dispatch(fetchUsersRequest({ page: 1, pageSize: 10 }));
  }, [dispatch]);
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  const handlePageChange = (page: number, pageSize?: number) => {
    dispatch(fetchUsersRequest({ page, pageSize: pageSize || 10 }));
  };

  const handleRowClick = (record: any) => {
    navigate(`/users/${record.id}`, { state: { record } });
  };

  const handleDelete = (e: React.MouseEvent, key: string) => {
    e.stopPropagation(); // Stop the onRow click event from triggering
    console.log(`Deleting ${key}`);

    confirm({
      title: "Are you sure you want to delete this user?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await api.deleteUser(key);
          dispatch(fetchUsersRequest({ page: 1, pageSize: pageSize || 10 }));
        } catch (error) {}
        // Add your delete logic here, e.g., dispatching a Redux action
      },
      onCancel: () => {
        console.log("Delete cancelled");
      },
    });
  };

  const handleSearch = () => {
    // Perform search filtering
    dispatch(fetchUsersRequest({ page: 1, pageSize: 10, query: searchQuery }));
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "username",
      key: "name",
    },
    {
      title: "Phone number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: { id: string }) => (
        <Button danger onClick={(e) => handleDelete(e, record.id)}>
          Delete
        </Button>
      ),
    },
  ];

  if (!user.isadmin) return null;

  return (
    <Layout style={{ minHeight: "100vh", background: "#f0f2f5" }}>
      <Content
        style={{
          padding: "24px",
          margin: 0,
          minHeight: 280,
          backgroundColor: "#fff",
        }}
      >
        <div style={{ marginBottom: 24 }}>
          <Row gutter={16} align="middle">
            <Col xs={24} sm={12} md={6} className="small-screen-margin">
              <Input
                placeholder="Search users"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onPressEnter={handleSearch} // Allow pressing Enter to search
              />
            </Col>
            <Col>
              <Button type="primary" onClick={handleSearch}>
                Search
              </Button>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <Button
                type="primary"
                onClick={() => {
                  navigate("/users/add");
                }}
              >
                Add new user
              </Button>
            </Col>
          </Row>
        </div>

        <Spin spinning={loading} tip="Loading...">
          <Table
            dataSource={data}
            columns={columns}
            pagination={{
              current: currentPage,
              pageSize,
              total,
              onChange: handlePageChange,
            }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowKey="key"
            style={{
              width: "100%", // Ensure the table fills the container
              overflow: "auto", // Handle overflow gracefully
            }}
          />
        </Spin>
      </Content>
    </Layout>
  );
};

export default AdminPage;
