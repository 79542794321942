import React, { useState } from "react";
import { message, Form, Input, Button, Layout, Row, Col } from "antd";

import { api } from "../../store/sagas/authSaga";
const { Content } = Layout;

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values: any) => {
    const { oldPassword, newPassword, confirmPassword } = values;

    if (newPassword === confirmPassword) {
      try {
        setLoading(true);
        await api.updatePwd({ oldPassword, newPassword });
        setLoading(false);
        form.resetFields();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    } else {
      message.error("Passwords do not match!");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#ffffb8",
        display: "flex",
        justifyContent: "center", // Center vertically
        alignItems: "center", // Center horizontally
        padding: "24px", // Add padding for spacing
      }}
    >
      <Content
        style={{
          width: "100%",
          maxWidth: "600px", // Make the form container wider
          background: "#fff",
          padding: "32px 48px", // Add padding for a larger form
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 style={{ textAlign: "center", marginBottom: "32px" }}>
          Change Password
        </h2>
        <Form
          form={form}
          name="changePassword"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ width: "100%" }} // Ensure the form takes the full container width
        >
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              { required: true, message: "Please enter your old password!" },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required: true, message: "Please enter your new password!" },
              {
                min: 8,
                message: "Password must be at least 8 characters long!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm New Password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm your new password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export default ChangePassword;
