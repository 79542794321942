// src/store/sagas/authSaga.ts
import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { loginRequest, loginSuccess, loginFailure } from "../slices/authSlice";
import { get, post, put as putApi, del } from "../../utils/axiosUtils";
import { message } from "antd";

export const api = {
  login: async (user: { username: string; password: string }) => {
    try {
      const response = await post("/login", user);
      console.log("response", response);
      message.success("Login successfully");
      return response;
    } catch (error) {
      const errorMessage =
        (error as any).response?.data?.error ||
        "Server error, please try later";
      message.error(errorMessage);
    }
  },

  updatePwd: async (data: { oldPassword: string; newPassword: string }) => {
    try {
      const response = await putApi("/changepassword", data);
      message.success("Change password successfully");
      return response;
    } catch (error) {
      const errorMessage =
        (error as any).response?.data?.error ||
        "Server error, please try later";
      message.error(errorMessage);
    }
  },

  deleteUser: async (id: string) => {
    try {
      console.log("id", id);
      const response = await post("/deleteusr", { id });
      console.log("response", response);
      message.success("Delete user successfully");
      return response;
    } catch (error) {
      console.log("error", error);
      const errorMessage =
        (error as any).response?.data?.message ||
        "Server error, please try later";
      message.error(errorMessage);

      throw error;
    }
  },
};

function* handleLogin(action: any): Generator {
  try {
    //todo
    const response = yield call(api.login, action.payload);

    const token = (response as { data: { token: string } }).data.token;
    const user = (response as { data: { user: any } }).data.user;
    console.log("token", token, user);
    if (token) {
      localStorage.setItem("token", token);

      yield put(loginSuccess({ token, user: user }));
    }
  } catch (error: any) {
    yield put(loginFailure(error.message || "Login failed"));
  }
}

export default function* authSaga() {
  yield takeLatest(loginRequest.type, handleLogin);
}
