import React from "react";
import { Form, Input, Button, Layout, Row, Col, Spin, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addUserRequest } from "../../store/slices/userSlice";
import { RootState } from "../../store/store";

const { Content } = Layout;

const CreateUser: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.user.loading);

  const onFinish = (values: any) => {
    console.log("Success:", values);
    dispatch(addUserRequest(values));
  };
  const user = useSelector((state: RootState) => state.auth.user);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  if (!user.isadmin) return null;

  return (
    <Layout
      style={{ minHeight: "100vh", backgroundColor: "#ffffb8", width: "100%" }}
    >
      <Content
        style={{
          padding: "24px",
          margin: 0,

          display: "flex", // Use flexbox
          flexDirection: "column", // Align children vertically

          alignItems: "center", // Center horizontally
        }}
      >
        <h2 style={{ marginBottom: 48 }}>Create new user</h2>

        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 24 }}
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ width: "100%", maxWidth: "1200px" }} // Limit form width and make it responsive
        >
          <Row gutter={16} style={{}}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="User name"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input user name!",
                  },
                ]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Confirm Password"
                name="confirmpassword"
                dependencies={["password"]} // Ensure the field depends on the password field
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Phone Number"
                name="phonenumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input placeholder="Enter phone number" />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              sm={12}
              md={12}
              style={{
                marginTop: 24, // Add space between the checkbox and the label
                display: "flex",
                alignItems: "center", // Vertically center the checkbox
              }}
            >
              <Form.Item name="is_admin" valuePropName="checked">
                <Checkbox>Is admin</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            wrapperCol={{ offset: 12, span: 16 }}
            style={{ marginTop: 40 }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export default CreateUser;
