import React, { useState } from "react";
import { Upload, Button, message, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { ServerUrl } from "../../utils/constant";
const { confirm } = Modal;

type Props = { imageList: any[]; updateImglist: any };
const ImageUpload = ({ imageList, updateImglist }: Props) => {
  //const [imageList, setImageList] = useState<any[]>(images);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");

  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      // Send the image to the server
      const response = await axios.post(ServerUrl + "/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const uri = response.data.url;
      const id = response.data.id;

      // Add the image to the list for preview
      updateImglist((prevList: any[]) => [
        ...prevList,
        {
          uid: id,
          name: file.name,
          url: uri,
          status: "done",
        },
      ]);

      message.success("Image uploaded successfully!");
    } catch (error) {
      console.error(error);
      message.error("Upload failed. Please try again.");
    }
  };

  const handleRemove = (file: any) => {
    confirm({
      title: "Are you sure you want to delete this phote?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        updateImglist(imageList.filter((image) => image.uid !== file.uid));
        message.success("Deleted successfully.");
        // Add your delete logic here, e.g., dispatching a Redux action
      },
      onCancel: () => {
        console.log("Delete cancelled");
      },
    });
  };

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
    setPreviewVisible(true);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <Upload
        customRequest={({ file, onSuccess, onError }) => {
          handleUpload(file)
            .then(() => onSuccess?.({}, file))
            .catch((error) => onError?.(error));
        }}
        listType="picture-card"
        fileList={imageList}
        onRemove={handleRemove}
        onPreview={handlePreview}
        accept="image/*"
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>

      {/* Built-in preview modal */}
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default ImageUpload;
