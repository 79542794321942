import React, { useState } from "react";
import { Table, Layout, Button } from "antd";
import { useNavigate } from "react-router-dom";

// Type definition for table data
interface TableData {
  key: string;
  name: string;
  age: number;
  address: string;
}

const { Content } = Layout;

const PawnedCars: React.FC = () => {
  const navigate = useNavigate(); // Hook to navigate between pages

  // Sample data for the table
  const data: TableData[] = [
    {
      key: "1",
      name: "John Doe",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "Jane Smith",
      age: 28,
      address: "20 Baker Street",
    },
    {
      key: "3",
      name: "Samuel Johnson",
      age: 45,
      address: "30 Park Avenue",
    },
    // Add more rows as needed
  ];

  // Columns configuration for the table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Phone number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: { key: string }) => (
        <Button danger onClick={() => handleDelete(record.key)}>
          Delete
        </Button>
      ),
    },
  ];

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Handle row click to navigate to details screen
  const handleRowClick = (record: TableData) => {
    navigate(`/details/${record.key}`);
  };

  // Handle delete action (just a simple example of logging the deleted key)
  const handleDelete = (key: string) => {
    console.log(`Deleting item with key: ${key}`);
    // You can add API call here to delete the item from the backend
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* <Content
        style={{
          padding: "24px",
          margin: 0,
          minHeight: 280,
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 24,
          }}
        >
          <h2>Car list</h2>
          <Button type="primary">
            <a href="/users/add">Add cars</a>
          </Button>
        </div>
        <Table<TableData>
          dataSource={data}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: data.length,
            onChange: handlePageChange,
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record), // Row click to navigate to details page
          })}
        />
      </Content> */}
    </Layout>
  );
};

export default PawnedCars;
