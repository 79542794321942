import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Spin,
  Alert,
  Layout,
  Row,
  Col,
  Checkbox,
} from "antd";

const { Content } = Layout;

// Define the structure of the item (you can adjust it based on your data)
interface ItemDetails {
  username: string;
  age: number;
  address: string;
}

const DetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Get the 'id' from the URL params
  //const [item, setItem] = useState<ItemDetails | null>(null); // State to hold fetched data
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [error, setError] = useState<string>(""); // Error state for handling fetch errors

  const location = useLocation();

  const item = location.state || {}; // Access the passed state safely
  console.log("item", item);
  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <Spin size="large" /> {/* Show loading spinner */}
      </div>
    ); // Show loading spinner
  }

  if (error) {
    return (
      <div style={{ marginTop: 20 }}>
        <Alert message={error} type="error" showIcon />{" "}
        {/* Show error message */}
      </div>
    );
  }

  if (!item) {
    return <div>Item not found.</div>; // Handle case if item doesn't exist
  }
  console.log("item", item);

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout
      style={{ minHeight: "100vh", backgroundColor: "#ffffb8", width: "100%" }}
    >
      <Content
        style={{
          padding: "24px",
          margin: 0,

          display: "flex", // Use flexbox
          flexDirection: "column", // Align children vertically

          alignItems: "center", // Center horizontally
        }}
      >
        <h2 style={{ marginBottom: 48 }}>User information</h2>

        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 24 }}
          initialValues={item?.record}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ width: "100%", maxWidth: "1200px" }} // Limit form width and make it responsive
        >
          <Row gutter={16} style={{}}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="User name"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input user name!",
                  },
                ]}
              >
                <Input placeholder="Enter your name" disabled />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                ]}
              >
                <Input placeholder="Enter your email" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Phone Number"
                name="phonenumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input placeholder="Enter phone number" disabled />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              sm={12}
              md={12}
              style={{
                marginTop: 24, // Add space between the checkbox and the label
                display: "flex",
                alignItems: "center", // Vertically center the checkbox
              }}
            >
              <Form.Item name="is_admin" valuePropName="checked">
                <Checkbox disabled>Is admin</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          {/* <Form.Item
            wrapperCol={{ offset: 12, span: 16 }}
            style={{ marginTop: 40 }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item> */}
        </Form>
      </Content>
    </Layout>
  );
};

export default DetailPage;
