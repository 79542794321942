import React, { useEffect, useState } from "react";
import "./antd.css";

import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Spin,
  Alert,
  Layout,
  Row,
  Col,
  Checkbox,
  Select,
  InputNumber,
  DatePicker,
} from "antd";
import ImageUpload from "./ImageUpload";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  addClientStart,
  vehiclePawnAgreementStart,
} from "../../store/slices/clientsSlice";
import { RootState } from "../../store/store";
const { TextArea } = Input;

const { Content } = Layout;
// Define the structure of the item (you can adjust it based on your data)
interface ItemDetails {
  username: string;
  age: number;
  address: string;
}

const CreateClient: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state: RootState) => state.user.loading);
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log("Success:", values);
    dispatch(addClientStart({ ...values, navigate: navigate }));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const pawnAgreement = async () => {
    try {
      dispatch(vehiclePawnAgreementStart(obj as any));
      const values = await form.validateFields(); // Validate fields
      console.log("Validated Data:", values);
    } catch (error) {
      console.error("Validation Failed:", error); // Validation errors are automatically displayed under Form.Item
    }
  };

  return (
    <Layout
      style={{
        minHeight: "80vh",
        backgroundColor: "#ffffb8",
        width: "100%",
      }}
    >
      <Content
        style={{
          padding: "24px",
          margin: 0,

          display: "flex", // Use flexbox
          flexDirection: "column", // Align children vertically

          alignItems: "center", // Center horizontally
        }}
      >
        <h2 style={{ marginBottom: 24 }}>Client information</h2>
        {/* Spacing between title and form */}
        {/* Ant Design Form for displaying item details */}
        <Form
          form={form}
          layout="vertical"
          name="client"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 24 }}
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ width: "100%", maxWidth: "1200px" }} // Limit form width and make it responsive
        >
          <Row gutter={16} style={{}}>
            {/* First Form Item - Username */}
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="First Name"
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please input first name!",
                  },
                ]}
              >
                <Input placeholder="Enter your first name" />
              </Form.Item>
            </Col>

            {/* Second Form Item - Password */}
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Please input last name!",
                  },
                ]}
              >
                <Input placeholder="Enter your last name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{}}>
            {/* First Form Item - Username */}
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Birthday" name="birthday">
                <DatePicker />
              </Form.Item>
            </Col>

            {/* Second Form Item - Password */}
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Email" name="email">
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            {/* First Form Item - Username */}
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Phone" name="phone">
                <Input />
              </Form.Item>
            </Col>

            {/* Second Form Item - Password */}
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Idendtification number" name="id">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            {/* Additional Notes aligned with the first column above */}
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Address" name="address">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Description" name="car_desc">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            {/* Additional Notes aligned with the first column above */}
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Weight(kg)"
                name="weight"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Height(cm)"
                name="height"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} justify={"center"}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Content>
    </Layout>
  );
};

export default CreateClient;

const obj = {
  username: "client-a",
  email: "a@gmail.com",
  phone: "6047777777",
  id: "123456",
  address: "11100 richmond ",
  model: "bmw",
  vin: "vin122",
  year: 1998,
  odometer: 1234,
  plate: "v6y7yu",
  "exterior-color": "ext color white",
  "interior-condition": "interior condition good",
  mechanical: "mechanical good ",
  "fuel-type": "gas",
  "transmission-type": "manual",
  "vehicle-modification": "Vehicle modification",
  "accessories-included": "yes",
  appraised: "560000",
  "exterior-condition": "Exterior condition good ",
  "loan-amount": "1898888",
  "loan-term": "365",
  "interstate-rate": "89",
  "document-fee": "120",
  "annual-rate": "20",
  "appraisal-fee": "10000",
  "storage-fee": "1000",
  "carfax-fee": "999",
  "insurance-fee": "888",
  "management-fee": "7777",
  agreement: true,
};
