// src/store/index.ts
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import authReducer from "./slices/authSlice";
import userReducer from "./slices/userSlice";
import clientReducer from "./slices/clientsSlice";
import contractReducer from "./slices/contractSlice";
import authSaga from "./sagas/authSaga";
import userSaga from "./sagas/userSaga";
import clientSagas from "./sagas/clientsSaga";
import contractSagas from "./sagas/contractSaga";
import { all } from "redux-saga/effects";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses localStorage by default

const rootSagas = function* rootSagas() {
  yield all([authSaga(), userSaga(), clientSagas(), contractSagas()]);
};

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    user: userReducer,
    clients: clientReducer,
    contracts: contractReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false, // Required for redux-persist
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSagas);

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
