// src/pages/Login.tsx
import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../store/store";
import { loginRequest } from "../store/slices/authSlice";
const Login: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);

  const { loading, error } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    try {
      // Mock API call to authenticate the user
      const { username, password } = values;

      // Replace this with your actual authentication API call

      //   localStorage.setItem("token", "your-jwt-token");
      dispatch(loginRequest(values));
    } catch (error) {
      message.error("Login failed");
    } finally {
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/clients");
    }
  }, [token]);

  return (
    <div style={{ width: 300, margin: "0 auto", paddingTop: 100 }}>
      <h2>Login</h2>
      <Form name="login" onFinish={onFinish}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
          initialValue={"admin"}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
          initialValue={""}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
