import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure,
  addUserSuccess,
  addUserRequest,
  addUserFailure,
} from "../slices/userSlice";
import { get, post, put as putApi, del } from "../../utils/axiosUtils";
import { message } from "antd";
interface FetchUsersAction {
  type: string;
  payload: { page: number; pageSize: number; query?: string };
}
// Sample API functions (replace these with your actual API functions)
const api = {
  fetchUsers: async (page: number, pageSize: number, query: string) => {
    const response = await get(`/users`, {
      params: { page, pageSize, query },
    });
    return response.data;
  },
  addUser: async (user: any) => {
    try {
      const response = await post("/adduser", user);
      console.log("User added:", response.data);
      message.success("User added successfully");
    } catch (error) {
      const errorMessage =
        (error as any).response?.data?.error || "Failed to add user";
      message.error(errorMessage);

      console.error("Error adding user:", error);
    }

    return { ...user, id: String(Date.now()) }; // Simulating user addition
  },
  updateUser: async (user: any) => {
    return user; // Simulating user update
  },
  deleteUser: async (id: string) => {
    return id; // Simulating user deletion
  },
};

// Worker Saga for fetching users
function* fetchUsersSaga(action: FetchUsersAction): Generator<any, void, any> {
  try {
    const { page, pageSize, query = "" } = action.payload;

    console.log(action.payload);
    const data = yield call(api.fetchUsers, page, pageSize, query || "");
    console.log("data", data);
    yield put(
      fetchUsersSuccess({
        data: data.items, // Adjust based on your API response
        currentPage: page,
        total: data.total,
      })
    );
  } catch (error: any) {
    yield put(fetchUsersFailure(error.message));
  }
}

// Worker Saga for adding a user
function* addUserSaga(action: any): Generator<any, void, any> {
  try {
    const user = yield call(api.addUser, action.payload);
    yield put(addUserSuccess(user));
  } catch (error) {
    yield put(addUserFailure("Failed to add user"));
  }
}

// Watcher Saga to watch for dispatched actions
function* userSaga() {
  yield takeEvery(fetchUsersRequest.type, fetchUsersSaga);
  yield takeEvery(addUserRequest.type, addUserSaga);
}

export default userSaga;
