import React, { useEffect, useState } from "react";
import {
  Table,
  Layout,
  Button,
  Spin,
  message,
  Modal,
  Input,
  Row,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchClientsStart } from "../../store/slices/contractSlice";
import { RootState } from "../../store/store";

const { Content } = Layout;

const ClientPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { clients, loading, currentPage, pageSize, total, error } = useSelector(
    (state: RootState) => state.contracts
  );

  useEffect(() => {
    dispatch(fetchClientsStart({ page: 1, pageSize: 10 }));
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  const handlePageChange = (page: number, pageSize?: number) => {
    dispatch(fetchClientsStart({ page, pageSize: pageSize || 10 }));
  };

  const handleRowClick = (record: any) => {
    navigate(`/clients/${record.id}`);
  };

  const columns = [
    {
      title: "Client name",
      dataIndex: "username",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "ID",
      dataIndex: "identification_number",
      key: "identification_number",
    },
    {
      title: "Agreement no",
      dataIndex: "ag_no",
      key: "ag_no",
    },
  ];
  const handleSearch = () => {};
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content
        style={{
          padding: "24px",
          margin: 0,
          minHeight: 280,
          backgroundColor: "#fff",
        }}
      >
        <div style={{ marginBottom: 24 }}>
          <Row gutter={16} align="middle">
            <Col xs={24} sm={12} md={6} className="small-screen-margin">
              <Input
                placeholder="Search "
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onPressEnter={handleSearch} // Allow pressing Enter to search
              />
            </Col>
            <Col>
              <Button type="primary" onClick={handleSearch}>
                Search
              </Button>
            </Col>
            <Col style={{ marginLeft: "auto" }}></Col>
          </Row>
        </div>

        <Spin spinning={loading} tip="Loading...">
          <Table
            dataSource={clients}
            columns={columns}
            pagination={{
              current: currentPage,
              pageSize,
              total,
              onChange: handlePageChange,
            }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowKey="id"
            style={{
              width: "100%", // Ensure the table fills the container
              overflow: "auto", // Handle overflow gracefully
            }}
          />
        </Spin>
      </Content>
    </Layout>
  );
};

export default ClientPage;
