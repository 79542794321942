import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { logout } from "../store/slices/authSlice";
import store from "../store/store"; // Import the store
import { ServerUrl } from "./constant";
// Create an Axios instance
const apiClient = axios.create({
  baseURL: ServerUrl, // Replace with your API base URL
  // baseURL: "https://amax-server.azurewebsites.net", // Replace with your API base URL
  timeout: 1000000, // Timeout in milliseconds
});

// Add a request interceptor to attach the auth token
apiClient.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("token"); // Retrieve the token from localStorage
    if (token) {
      config.headers = {
        //...config.headers,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Attach the token to the Authorization header
      };
    }
    return config;
  },
  (error: AxiosError) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle responses globally
apiClient.interceptors.response.use(
  (response: AxiosResponse) => response, // Pass the response data directly
  (error: AxiosError) => {
    // Handle response error, e.g., logging out user on 401 error
    if (error.response?.status === 401) {
      console.error("Unauthorized! Redirecting to login...");
      // Perform logout or redirect to login
      localStorage.removeItem("token");
      store.dispatch(logout());
      window.location.href = "/login"; // Replace with your login page
    }
    return Promise.reject(error);
  }
);

// Utility functions for common API requests
export const get = (url: string, config?: AxiosRequestConfig) =>
  apiClient.get(url, config);
export const post = (url: string, data?: any, config?: AxiosRequestConfig) =>
  apiClient.post(url, data, config);
export const put = (url: string, data?: any, config?: AxiosRequestConfig) =>
  apiClient.put(url, data, config);
export const del = (url: string, config?: AxiosRequestConfig) =>
  apiClient.delete(url, config);

export default apiClient;
