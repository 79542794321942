import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the User type
interface User {
  id: string;
  username: string;
  phonenumber: string;
  key: string;
}

interface UsersState {
  data: User[];
  loading: boolean;
  error: string | null;
  currentPage: number;
  pageSize: number;
  total: number;
}

// Initial state for the users
const initialState: UsersState = {
  data: [],
  loading: false,
  error: null,
  currentPage: 1,
  pageSize: 10,
  total: 0,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    fetchUsersRequest(
      state,
      action: PayloadAction<{ page: number; pageSize: number; query?: string }>
    ) {
      state.loading = true;
      state.error = null;
    },
    fetchUsersSuccess(
      state,
      action: PayloadAction<{
        data: User[];
        currentPage: number;
        total: number;
      }>
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.currentPage = action.payload.currentPage;
      state.total = action.payload.total;
    },
    fetchUsersFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },

    addUserRequest(state, action: PayloadAction<User>) {
      state.loading = true;
    },
    addUserSuccess(state, action: PayloadAction<User>) {
      state.loading = false;
    },
    addUserFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Actions
export const {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure,
  addUserRequest,
  addUserSuccess,
  addUserFailure,
} = usersSlice.actions;

// Reducer
export default usersSlice.reducer;
