import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./antd.css";

import axios from "axios";
import {
  Form,
  Input,
  Button,
  Spin,
  Alert,
  Layout,
  Row,
  Col,
  Checkbox,
  Select,
  InputNumber,
  message,
  DatePicker,
} from "antd";
import ImageUpload from "./ImageUpload";

import { useDispatch, useSelector } from "react-redux";
import {
  addClientStart,
  vehiclePawnAgreementStart,
} from "../../store/slices/clientsSlice";
import { RootState } from "../../store/store";
import { saveAs } from "file-saver";
import moment from "moment";
import { api } from "../../store/sagas/clientsSaga";
const { TextArea } = Input;

const { Content } = Layout;

// Define the structure of the item (you can adjust it based on your data)
interface ItemDetails {
  username: string;
  age: number;
  address: string;
  images: any[];
  birthday: Date | null;
}

const DetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Get the 'id' from the URL params
  const [item, setItem] = useState<ItemDetails | null>(null); // State to hold fetched data
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [error, setError] = useState<string>(""); // Error state for handling fetch errors

  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.user.loading);
  const [form] = Form.useForm();
  const [imageList, setImageList] = useState<any[]>(item?.images || []);
  const fetchData = async (id: number) => {
    setLoading(true); // Set loading to true
    try {
      const response = await api.fetchClient(id);

      console.log("response", response);
      setItem({
        ...response,
        birthday: response.birthday ? new Date(response.birthday as any) : null,
      }); // Set fetched data to state
      setImageList(response.images);
    } catch (error) {
      setError((error as any).message); // Set error message
    } finally {
      setLoading(false); // Set loading to false
    }
  };
  useEffect(() => {
    console.log("xxx", id);
    if (id) {
      fetchData(id as any);
      //  setItem({ username: "John Doe", age: 32, address: "10 Downing Street" });
    }
  }, [id]); // Re-run the effect if the 'id' changes

  if (loading) {
    return (
      <div
        style={{
          display: "flex", // Enable flexbox
          alignItems: "center", // Center items vertically
          justifyContent: "center", // Center items horizontally
          height: "100%", // Ensure the container takes the full height
          width: "100%", // Ensure the container takes the full width
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ marginTop: 20 }}>
        <Alert message={error} type="error" showIcon />{" "}
      </div>
    );
  }

  if (!item) {
    return <div>Item not found.</div>; // Handle case if item doesn't exist
  }
  console.log("images", imageList);

  const handleSubmit = async (values: any) => {
    try {
      const values = await form.validateFields(); // Validate form
      setLoading(true); // Set loading to true
      console.log("values", values);
      try {
        const response = await api.updateClient({
          ...values,
          id,
          images: imageList,
        });

        console.log("response", response);
        message.success("Client updated successfully");
        const updated = await api.fetchClient(id as any);

        console.log("response", response);
        setItem(updated);
      } catch (error) {
        message.error("Failed to update client");
      } finally {
        setLoading(false); // Set loading to false
      }
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo);
    }
  };

  const handlPawnAgreement = async () => {
    try {
      const values = await form.validateFields(); // Validate form
      setLoading(true); // Set loading to true
      console.log("values", values);
      try {
        // const response = await api.updateClient({
        //   ...values,
        //   id,
        //   images: imageList,
        // });

        const response = await api.vehiclePawnAgreementApi({
          ...values,
          id,
          images: imageList,
        });
        await saveAs(
          response.data,
          values.firstname + "_" + values.lastname + "_PA.pdf"
        );
      } catch (error) {
        message.error("Failed to update client");
      } finally {
        setLoading(false); // Set loading to false
      }
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo);
    }
  };

  const handlDeclarationOwnership = async () => {
    try {
      const values = await form.validateFields(); // Validate form
      setLoading(true); // Set loading to true
      console.log("values", values);
      try {
        const response = await api.declarationOwnershipApi({
          ...values,
          id,
          images: imageList,
        });
        await saveAs(
          response.data,
          values.firstname + "_" + values.lastname + "_DO.pdf"
        );
      } catch (error) {
        message.error("Failed to update client");
      } finally {
        setLoading(false); // Set loading to false
      }
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo);
    }
  };

  const handlAssignmentPaymentAgreement = async () => {
    try {
      const values = await form.validateFields(); // Validate form
      setLoading(true); // Set loading to true
      console.log("values", values);
      try {
        const response = await api.assignmentPaymentAgreement({
          ...values,
          id,
          images: imageList,
        });
        await saveAs(
          response.data,
          values.firstname + "_" + values.lastname + "_APA.pdf"
        );
      } catch (error) {
        message.error("Failed to update client");
      } finally {
        setLoading(false); // Set loading to false
      }
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo);
    }
  };

  const handlVehicleCollateralCustodyAgreement = async () => {
    try {
      const values = await form.validateFields(); // Validate form
      setLoading(true); // Set loading to true
      console.log("values", values);
      try {
        const response = await api.vehicleCollateralCustodyAgreement({
          ...values,
          id,
          images: imageList,
        });
        await saveAs(
          response.data,
          values.firstname + "_" + values.lastname + "_CC.pdf"
        );
      } catch (error) {
        message.error("Failed to update client");
      } finally {
        setLoading(false); // Set loading to false
      }
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo);
    }
  };

  const handlGovBackup = async () => {
    try {
      const values = await form.validateFields(); // Validate form
      setLoading(true); // Set loading to true
      console.log("values", values);
      try {
        const response = await api.govBackup({
          ...values,
          id,
          images: imageList,
        });
        await saveAs(
          response.data,
          values.firstname + "_" + values.lastname + "SPR.pdf"
        );
      } catch (error) {
        message.error("Failed to update client");
      } finally {
        setLoading(false); // Set loading to false
      }
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo);
    }
  };

  const handlLoanExtensionAgreement = async () => {
    try {
      const values = await form.validateFields(); // Validate form
      setLoading(true); // Set loading to true
      console.log("values", values);
      try {
        const response = await api.loanExtensionAgreement({
          ...values,
          id,
          images: imageList,
        });
        await saveAs(
          response.data,
          values.firstname + "_" + values.lastname + "_EA.pdf"
        );
      } catch (error) {
        message.error("Failed to update client");
      } finally {
        setLoading(false); // Set loading to false
      }
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo);
    }
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        backgroundColor: "#ffffb8",
        width: "100%",
      }}
    >
      <Content
        style={{
          padding: "24px",
          margin: 0,

          display: "flex", // Use flexbox
          flexDirection: "column", // Align children vertically

          alignItems: "center", // Center horizontally
        }}
      >
        <h2 style={{ marginBottom: 24 }}>Client information</h2>

        <Form
          form={form}
          layout="vertical"
          name="client"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 24 }}
          initialValues={{
            ...item,
            agreement: null,
            birthday: moment(item.birthday),
          }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{ width: "100%", maxWidth: "1200px" }} // Limit form width and make it responsive
        >
          <Row gutter={16} style={{}}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="First name"
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please first name!",
                  },
                ]}
              >
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Last name"
                name="lastname"
                rules={[{ required: true, message: "Please input last name!" }]}
              >
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{}}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Birthday" name="birthday">
                <DatePicker />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Idendtification number"
                name="identification_number"
                rules={[{ required: true, message: "Please input your id!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  { required: true, message: "Please input your address!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Description" name="car_desc">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Weight(kg)"
                name="weight"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Height(cm)"
                name="height"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} justify="center" align="middle">
            <Col xs={24} sm={12} md={12}>
              <h2
                style={{
                  marginBottom: 24,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                Vehicle Pawn information
              </h2>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Make/Model"
                name="model"
                rules={[
                  {
                    required: true,
                    message: "Please input make/model!",
                  },
                ]}
              >
                <Input placeholder="Enter make/model" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="VIN"
                name="vin"
                rules={[{ required: true, message: "Please input the VIN!" }]}
              >
                <Input placeholder="Enter VIN" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Year" name="year">
                <InputNumber
                  style={{ width: "100%" }}
                  min={1980} // Set minimum year
                  max={new Date().getFullYear()} // Set maximum year as current year
                  formatter={(value) => `${value}`.replace(/\D/g, "")} // Only allow numbers
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Odometer reading"
                name="odometer"
                rules={[
                  {
                    required: true,
                    message: "Please input the odometer reading!",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) => `${value}`.replace(/\D/g, "")} // Only allow numbers
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="License plate number" name="plate">
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Exterior color"
                name="exterior_color"
                rules={[
                  { required: true, message: "Please input exterior color!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Mechanical" name="mechanical">
                <TextArea rows={3} placeholder="Enter Mechanical " />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Exterior condition" name="exterior_condition">
                <TextArea
                  rows={3}
                  placeholder="Please input exterior condition"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Interior condition" name="interior_condition">
                <TextArea rows={3} placeholder="Enter additional notes" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Mechanical condition"
                name="mechanical_condition"
              >
                <TextArea
                  rows={3}
                  placeholder="Please input mechanical condition"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Fuel type" name="fuel_type">
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Transmission type"
                name="transmission_type"
                rules={[
                  {
                    required: true,
                    message: "Please input the transmission type!",
                  },
                ]}
              >
                <Select placeholder="Select">
                  <Select.Option value="manual">Manual</Select.Option>
                  <Select.Option value="auto">Auto</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Vehicle modification"
                name="vehicle_modification"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Accessories included"
                name="accessories_included"
              >
                <Select placeholder="Select Yes/No">
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Appraised value"
                name="appraised_value"
                rules={[
                  {
                    required: true,
                    message: "Please input the appraised value!",
                  },
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Loan term (days)"
                name="loan_term"
                rules={[
                  { required: true, message: "Please input the loan term!" },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Type of artical"
                name="artical_type"
                rules={[
                  {
                    required: true,
                    message: "Please select type!",
                  },
                ]}
              >
                <Select placeholder="Select">
                  <Select.Option value="SUV">SUV</Select.Option>
                  <Select.Option value="Sedan">Sedan</Select.Option>
                  <Select.Option value="Sports">Sports</Select.Option>
                  <Select.Option value="Hatchbact">Hatchbact</Select.Option>
                  <Select.Option value="Truck">Truck</Select.Option>
                  <Select.Option value="Motorcycle">Motorcycle</Select.Option>
                  <Select.Option value="Other">Other</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Document Fee"
                name="document_fee"
                rules={[
                  {
                    required: true,
                    message: "Please input the document fee!",
                  },
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Effective annual rate (APR) (%)"
                name="annual_rate"
                rules={[
                  {
                    required: true,
                    message: "Please input the effective annual rate!",
                  },
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" min={12} max={32} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Appraisal Fee"
                name="appraisal_fee"
                rules={[
                  {
                    required: true,
                    message: "Please input the appraisal fee!",
                  },
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Storage Fee (per day)"
                name="storage_fee"
                rules={[
                  {
                    required: true,
                    message: "Please input the storage fee!",
                  },
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Carfax Fee"
                name="carfax_fee"
                rules={[
                  {
                    required: true,
                    message: "Please input the Carfax fee!",
                  },
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Insurance Fee"
                name="insurance_fee"
                rules={[
                  {
                    required: true,
                    message: "Please input the insurance fee!",
                  },
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Vehicle Management Fee"
                name="management_fee"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Extension term (days)" name="extension_days">
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}></Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Pawn item picture">
                <ImageUpload
                  imageList={imageList}
                  updateImglist={setImageList}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}></Col>
          </Row>

          <Row gutter={16} justify="center" align="middle">
            <Col xs={24} sm={12} md={12}>
              <h2
                style={{
                  marginBottom: 24,
                  marginTop: 0,
                  textAlign: "center",
                }}
              >
                Custody information
              </h2>
            </Col>
          </Row>

          <Row gutter={16} style={{}}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Company name" name="company_name">
                <Input placeholder="Enter company name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Business license nmuber" name="license_number">
                <Input placeholder="Enter Business license nmuber" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{}}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Company address" name="company_address">
                <Input placeholder="Enter company address" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Company phone number" name="company_phone">
                <Input placeholder="Enter company phone number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} style={{}}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Company email" name="company_email">
                <Input placeholder="Enter company email" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}></Col>
          </Row>

          <Row gutter={16} justify="center" align="middle">
            <Col xs={24} sm={12} md={12}>
              <h2
                style={{
                  marginBottom: 24,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                Loan Extension information
              </h2>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Extension term (days)" name="extension_days">
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Appraisal Fee"
                name="ex_appraisal_fee"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Storage Fee (per day)"
                name="ex_storage_fee"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Carfax Fee"
                name="ex_carfax_fee"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Insurance Fee"
                name="ex_insurance_fee"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Vehicle Management Fee"
                name="ex_management_fee"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Document Fee"
                name="ex_document_fee"
                rules={[
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message:
                      "Please input a valid number with up to two decimal places!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={12}></Col>
          </Row>

          <Row gutter={16} justify="center" align="middle">
            <Col xs={24} sm={12} md={12}>
              <h2
                style={{
                  marginBottom: 24,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                Third-party payee information
              </h2>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Name" name="th_name">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Address" name="th_address">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Phone" name="th_phone">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Email" name="th_email">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Bankaccount number (7digit)"
                name="th_bank_number"
                rules={[
                  {
                    validator: (_, inputValue) =>
                      inputValue?.toString().length === 7 ||
                      inputValue === undefined ||
                      inputValue?.toString().length === 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Need 7 digits")),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Transit number (5digit)"
                name="th_bank_transit"
                rules={[
                  {
                    validator: (_, inputValue) =>
                      inputValue?.toString().length === 5 ||
                      inputValue === undefined ||
                      inputValue?.toString().length === 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Need 5 digits")),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                label="Institution number (3digit)"
                name="th_bank_inst"
                rules={[
                  {
                    validator: (_, inputValue) =>
                      inputValue?.toString().length === 3 ||
                      inputValue === undefined ||
                      inputValue?.toString().length === 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Need 3 digits")),
                  },
                ]}
              >
                <Input max={999} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Bank name" name="th_bank_name">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} justify="start">
            <Col xs={24}>
              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message:
                      "You must agree to the terms and conditions before submitting!",
                  },
                ]}
              >
                <Checkbox>
                  I agree to the pre-deduction of interest and all applicable
                  fees (including third-party fees) from the loan amount.
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify={"center"}>
            <Form.Item>
              <Button type="primary" onClick={handleSubmit}>
                Save
              </Button>
            </Form.Item>
          </Row>

          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={12} md={8}>
              <Form.Item>
                <Button type="primary" onClick={handlPawnAgreement} block>
                  Pawn Agreement
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handlDeclarationOwnership}
                  block
                >
                  Declaration of Ownership
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={12} md={8}>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handlVehicleCollateralCustodyAgreement}
                  block
                >
                  Collater custody
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handlAssignmentPaymentAgreement}
                  block
                >
                  Assignment of payment agreement
                </Button>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} sm={12} md={12}>
              <Form.Item>
                <Button type="primary" onClick={handlGovBackup} block>
                  Second hand dealers and pawnbrokers register
                </Button>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handlLoanExtensionAgreement}
                  block
                >
                  Loan Extension Agreement
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Layout>
  );
};

const obj = {
  username: "client-a",
  email: "a@gmail.com",
  phone: "6047777777",
  id: "123456",
  address: "11100 richmond ",
  model: "bmw",
  vin: "vin122",
  year: 1998,
  odometer: 1234,
  plate: "v6y7yu",
  "exterior-color": "ext color white",
  "interior-condition": "interior condition good",
  mechanical: "mechanical good ",
  "fuel-type": "gas",
  "transmission-type": "manual",
  "vehicle-modification": "Vehicle modification",
  "accessories-included": "yes",
  appraised: "560000",
  "exterior-condition": "Exterior condition good ",
  "loan-amount": "1898888",
  "loan-term": "365",
  "interstate-rate": "89",
  "document-fee": "120",
  "annual-rate": "20",
  "appraisal-fee": "10000",
  "storage-fee": "1000",
  "carfax-fee": "999",
  "insurance-fee": "888",
  "management-fee": "7777",
  agreement: true,
};

export default DetailPage;

// import React from "react";
// import { Form, Checkbox, Button } from "antd";

// const MyForm = () => {
//   const onFinish = (values: any) => {
//     console.log("Form submitted successfully:", values);
//   };

//   const onFinishFailed = (errorInfo: any) => {
//     console.error("Validation failed:", errorInfo);
//   };

//   return (
//     <Form
//       name="agreementForm"
//       onFinish={onFinish}
//       onFinishFailed={onFinishFailed}
//     >
// <Form.Item
//   name="agreement"
//   valuePropName="checked"
//   rules={[
//     {
//       required: true,
//       message:
//         "You must agree to the terms and conditions before submitting!",
//     },
//   ]}
// >
//   <Checkbox>
//     I agree to the pre-deduction of interest and all applicable fees
//     (including third-party fees) from the loan amount.
//   </Checkbox>
// </Form.Item>

//       <Form.Item>
//         <Button type="primary" htmlType="submit">
//           Submit
//         </Button>
//       </Form.Item>
//     </Form>
//   );
// };

// export default MyForm;
