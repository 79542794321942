// src/redux/clientsSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Client {
  id: string;
  name: string;
  email: string;
  key: string;
  // Add other properties as needed
}

interface ClientsState {
  clients: Client[];

  loading: boolean;
  error: string | null;
  currentPage: number;
  pageSize: number;
  total: number;
}

const initialState: ClientsState = {
  clients: [],
  loading: false,
  error: null,

  currentPage: 1,
  pageSize: 10,
  total: 0,
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    fetchClientsStart: (
      state,
      action: PayloadAction<{ page: number; pageSize: number; query?: string }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    fetchClientsSuccess: (
      state,
      action: PayloadAction<{
        data: Client[];
        currentPage: number;
        total: number;
      }>
    ) => {
      console.log(action.payload);
      state.loading = false;
      state.clients = action.payload.data;
      state.currentPage = action.payload.currentPage;
      state.total = action.payload.total;
    },
    fetchClientsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addClientStart: (state) => {
      state.loading = true;
    },
    addClientSuccess: (state, action: PayloadAction<Client>) => {
      state.loading = false;
      state.clients.push(action.payload);
    },
    addClientFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateClientStart: (state) => {
      state.loading = true;
    },
    updateClientSuccess: (state, action: PayloadAction<Client>) => {
      state.loading = false;
      const index = state.clients.findIndex(
        (client) => client.id === action.payload.id
      );
      if (index !== -1) {
        state.clients[index] = action.payload;
      }
    },
    updateClientFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteClientStart: (state) => {
      state.loading = true;
    },
    deleteClientSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.clients = state.clients.filter(
        (client) => client.id !== action.payload
      );
    },
    deleteClientFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    vehiclePawnAgreementStart: (state) => {
      state.loading = true;
    },
    vehiclePawnAgreementDone: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchClientsStart,
  fetchClientsSuccess,
  fetchClientsFailure,
  addClientStart,
  addClientSuccess,
  addClientFailure,
  updateClientStart,
  updateClientSuccess,
  updateClientFailure,
  deleteClientStart,
  deleteClientSuccess,
  deleteClientFailure,
  vehiclePawnAgreementStart,
  vehiclePawnAgreementDone,
} = clientsSlice.actions;

export default clientsSlice.reducer;
