// src/redux/clientsSaga.ts

import { takeEvery, call, put } from "redux-saga/effects";
import axios from "axios";
import { get, post, put as putApi, del } from "../../utils/axiosUtils";
import { message } from "antd";
import { saveAs } from "file-saver";

import {
  fetchClientsStart,
  fetchClientsSuccess,
  fetchClientsFailure,
  addClientStart,
  addClientSuccess,
  addClientFailure,
  updateClientStart,
  updateClientSuccess,
  updateClientFailure,
  deleteClientStart,
  deleteClientSuccess,
  deleteClientFailure,
  vehiclePawnAgreementStart,
  vehiclePawnAgreementDone,
} from "../slices/contractSlice";

// Define API endpoints
const API_URL = "http://localhost:3000/clients"; // Update with your API URL

interface Client {
  id: string;
  username: string;
  email: string;
  phone: string;
  // Add other fields as necessary
  exteriorColor: string;
  exteriorCondition: string;
  fuelType: string;
  model: string;
  vin: string;
  year: string;
  navigate: any;
}

export const api = {
  addClient: async (client: any) => {
    try {
      const response = await post("/addClient", client);
      console.log("response", response);
      message.success("New client added successfully");
      return response;
    } catch (error) {
      const errorMessage =
        (error as any).response?.data?.error || "Failed to save client";
      message.error(errorMessage);

      throw error;
    }
  },
  fetchClients: async (page: number, pageSize: number, query = "") => {
    const response = await get(`/contracts`, {
      params: { page, pageSize, query },
    });
    return response.data;
  },
  fetchClient: async (clientId: number) => {
    const response = await get(`/clients`, {
      params: { id: clientId },
    });
    return response.data;
  },
  updateClient: async (client: any) => {
    const response = await post(`/updateClient`, {
      ...client,
    });
    return response.data;
  },
};

// Fetch clients from API

function* fetchClient(action: {
  type: string;
  payload: { page: number; pageSize: number };
}): Generator<any, void, any> {
  try {
    const { page, pageSize } = action.payload;
    const data = yield call(api.fetchClients, page, pageSize);

    yield put(
      fetchClientsSuccess({
        data: data.items, // Adjust based on your API response
        currentPage: page,
        total: data.total,
      })
    );
  } catch (error) {
    yield put(fetchClientsFailure((error as any).message));
  }
}

function* fetchClients(action: {
  type: string;
  payload: { page: number; pageSize: number; query?: string };
}): Generator<any, void, any> {
  try {
    const { page, pageSize, query } = action.payload;
    const data = yield call(api.fetchClients, page, pageSize, query);

    yield put(
      fetchClientsSuccess({
        data: data.items, // Adjust based on your API response
        currentPage: page,
        total: data.total,
      })
    );
  } catch (error) {
    yield put(fetchClientsFailure((error as any).message));
  }
}

// Add a new client to the database
function* addClient(action: {
  type: string;
  payload: Client;
}): Generator<any, void, any> {
  try {
    // const response = yield call(api.addClient, action.payload);
    const response = yield call(api.addClient, action.payload);
    const { navigate } = action.payload;

    console.log("response", response);
    navigate(`/clients/${response.data.id}`);
    yield put(addClientSuccess(response.data));
  } catch (error) {
    yield put(addClientFailure((error as any).message));
  }
}

// Update a client in the database
function* updateClient(action: {
  type: string;
  payload: Client;
}): Generator<any, void, any> {
  try {
    const response = yield call(
      axios.put,
      `${API_URL}/${action.payload.id}`,
      action.payload
    );
    yield put(updateClientSuccess(response.data));
  } catch (error) {
    yield put(updateClientFailure((error as any).message));
  }
}

// Delete a client from the database
function* deleteClient(action: { type: string; payload: string }) {
  try {
    yield call(axios.delete, `${API_URL}/${action.payload}`);
    yield put(deleteClientSuccess(action.payload));
  } catch (error) {
    yield put(deleteClientFailure((error as any).message));
  }
}

// Watcher Saga
export default function* clientSagas() {
  yield takeEvery(fetchClientsStart.type, fetchClients);
  yield takeEvery(addClientStart.type, addClient);
  yield takeEvery(updateClientStart.type, updateClient);
  yield takeEvery(deleteClientStart.type, deleteClient);
}

const cli = {
  username: "u",
  email: "8",
  phone: "8",
  id: "8",
  model: "8",
  vin: "8",
  year: "8",
  odometer: "8",
  plate: "8",
  "exterior-color": "8",
  "interior-condition": "7",
  "mechanical-condition": "8",
  "fuel-type": "8",
  "transmission-type": "manual",
  "vehicle-modification": "8",
  "accessories-included": "yes",
  appraised: "7",
  "exterior-condition": "7",
  mechanical: "7",
  "loan-amount": "7",
  "loan-term": "7",
  "interstate-rate": "7",
  "document-fee": "7",
  "annual-rate": "7",
  "appraisal-fee": "7",
  "storage-fee": "7",
  "carfax-fee": "7",
  "insurance-fee": "7",
  "management-fee": "7",
  agreement: true,
};
