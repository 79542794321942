import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import { Layout, Menu, Button, Drawer } from "antd";
import {
  MenuOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  FileTextOutlined,
  CarOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./store/store";
import { logout } from "./store/slices/authSlice";
import { useMobile } from "./MobileContext";

const { Sider, Content } = Layout;

const MainLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.auth.token);

  const user = useSelector((state: RootState) => state.auth.user);
  const { isMobile } = useMobile();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token") && !token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(logout());
    navigate("/login");
  };

  if (!token) return null; // Prevent rendering layout before authentication

  return (
    <Layout style={{ height: "100vh" }}>
      {!isMobile && (
        <>
          <Sider
            width={250}
            className="site-layout-background"
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              position: "fixed",
              left: 0,
              backgroundColor: "white",
            }}
          >
            <Menu
              mode="inline"
              style={{
                flex: 1,
                borderRight: 0,
                paddingTop: 40,
              }}
            >
              {user.isadmin && (
                <Menu.Item
                  key="users"
                  icon={<UserOutlined />}
                  style={{ height: 50 }}
                >
                  <NavLink
                    to="/users"
                    style={{ fontSize: 18 }}
                    className={({ isActive }: { isActive: boolean }) =>
                      isActive ? "ant-menu-item-selected" : ""
                    }
                  >
                    Users
                  </NavLink>
                </Menu.Item>
              )}
              <Menu.Item
                key="clients"
                icon={<UsergroupAddOutlined />}
                style={{ height: 50 }}
              >
                <NavLink
                  to="/clients"
                  style={{ fontSize: 18 }}
                  className={({ isActive }: { isActive: boolean }) =>
                    isActive ? "ant-menu-item-selected" : ""
                  }
                >
                  Clients
                </NavLink>
              </Menu.Item>
              <Menu.Item
                key="contracts"
                icon={<FileTextOutlined />}
                style={{ height: 50 }}
              >
                <NavLink
                  to="/contracts"
                  style={{ fontSize: 18 }}
                  className={({ isActive }: { isActive: boolean }) =>
                    isActive ? "ant-menu-item-selected" : ""
                  }
                >
                  Contracts
                </NavLink>
              </Menu.Item>
              <Menu.Item
                key="pawnedcars"
                icon={<CarOutlined />}
                style={{ height: 50 }}
                className={`${({ isActive }: { isActive: boolean }) =>
                  isActive ? "ant-menu-item-selected" : ""}`}
              >
                <NavLink to="/pawnedcars" style={{ fontSize: 18 }}>
                  Pawned Cars
                </NavLink>
              </Menu.Item>
              <Menu.Item
                key="profile"
                icon={<UserOutlined />}
                style={{ height: 50 }}
                className={`${({ isActive }: { isActive: boolean }) =>
                  isActive ? "ant-menu-item-selected" : ""}`}
              >
                <NavLink to="/changepassword" style={{ fontSize: 18 }}>
                  Change Password
                </NavLink>
              </Menu.Item>
            </Menu>
            {/* Logout Button at the Bottom */}
            <div
              style={{
                marginTop: "auto",
                padding: "20px",
                borderTop: "1px solid #f0f0f0",
                textAlign: "center",
                alignSelf: "flex-end",
                // flex: 1,
                position: "fixed",
                bottom: 0,
                // backgroundColor: "yellow",
                width: 250,
              }}
            >
              <Button
                type="primary"
                danger
                icon={<LogoutOutlined />}
                onClick={handleLogout}
                style={{ width: "100%" }}
              >
                Logout
              </Button>
            </div>
          </Sider>
        </>
      )}
      {isMobile && (
        <>
          <Button
            className="menu-button"
            type="primary"
            icon={<MenuOutlined />}
            onClick={() => setVisible(true)}
            style={{ position: "absolute", top: "20px", left: "20px" }}
          />
          <Drawer
            title="Menu"
            placement="left"
            onClose={() => setVisible(false)}
            visible={visible}
          >
            <Menu mode="vertical" onClick={() => setVisible(false)}>
              {user.isadmin && (
                <Menu.Item key="users" icon={<UserOutlined />}>
                  <NavLink to="/users">Users</NavLink>
                </Menu.Item>
              )}
              <Menu.Item key="clients" icon={<UsergroupAddOutlined />}>
                <NavLink to="/clients">Clients</NavLink>
              </Menu.Item>
              <Menu.Item key="contracts" icon={<FileTextOutlined />}>
                <NavLink to="/contracts">Contracts</NavLink>
              </Menu.Item>
              <Menu.Item key="pawnedcars" icon={<CarOutlined />}>
                <NavLink to="/pawnedcars">Pawned Cars</NavLink>
              </Menu.Item>
              <Menu.Item key="changepassword" icon={<UserOutlined />}>
                <NavLink to="/changepassword">Change password</NavLink>
              </Menu.Item>
              <Menu.Item
                key="logout"
                icon={<LogoutOutlined />}
                onClick={handleLogout}
              >
                Logout
              </Menu.Item>
            </Menu>
          </Drawer>
        </>
      )}
      <Layout
        style={{
          marginLeft: isMobile ? 0 : 250,
          overflow: "hidden",
        }}
      >
        <Content
          style={{
            paddingLeft: !isMobile ? 80 : 0,
            paddingRight: !isMobile ? 80 : 0,
            //padding: !isMobile ? 80 : 0,
            paddingTop: !isMobile ? 40 : 0,
            paddingBottom: !isMobile ? 40 : 0,
            margin: 0,
            height: "100vh",
            overflow: "auto",
            marginTop: isMobile ? 60 : 0,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
