import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "antd/dist/reset.css"; // Import Ant Design styles
import { MobileProvider } from "./MobileContext";
import MainLayout from "./MainLayout"; // New layout component
import AdminPage from "./pages/Admin";
import Clients from "./pages/Clients";
import Contracts from "./pages/Contracts";
import PawnedCars from "./pages/PawnedCars";
import Login from "./pages/Login";
import UserDetail from "./pages/Admin/detail";
import UserCreate from "./pages/Admin/create";
import Profile from "./pages/Admin/profile";
import ClientsDetail from "./pages/Clients/detail";
import AddClient from "./pages/Clients/create";
const App = () => {
  return (
    <MobileProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* Redirect the root path to /clients */}
          <Route path="/" element={<Navigate to="/clients" />} />

          {/* Define MainLayout as the parent */}
          <Route path="/" element={<MainLayout />}>
            <Route path="users" element={<AdminPage />} />
            <Route path="clients" element={<Clients />} />
            <Route path="contracts" element={<Contracts />} />
            <Route path="pawnedcars" element={<PawnedCars />} />
            <Route path="users/:id" element={<UserDetail />} />
            <Route path="users/add" element={<UserCreate />} />
            <Route path="clients/:id" element={<ClientsDetail />} />
            <Route path="clients/add" element={<AddClient />} />
            <Route path="changepassword" element={<Profile />} />
          </Route>

          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </MobileProvider>
  );
};

export default App;
